<template>
  <div class="order-mock-form">
    <div class="d-flex justify-space-between align-center mb-4">
      <h1 v-if="receipt_id !== null && typeof receipt_id != 'undefined'">Order Mock Edit Form for {{ receipt_id }}</h1>
      <h1 v-if="receipt_id == null || typeof receipt_id == 'undefined'">Order Mock Create Form </h1>

      <v-btn small color="primary" @click="viewOrders">View Order</v-btn>
    </div>
    <form @submit.prevent="submitForm">
      <div
        class="product-selection-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <h3 style="margin-top: 0; margin-bottom: 15px">Product Selection</h3>
        <div v-if="receipt_id == null || typeof receipt_id == 'undefined'" class="form-row">
          <div class="form-group">
            <label for="product">Product</label>
            <v-combobox
              v-model="selectedProduct"
              :items="productOptions"
              label="Select Products"
              item-text="title"
              item-value="id"
              clearable
              @update:search-input="searchProducts"
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip v-bind="attrs" :input-value="selected" closable @click="select">
                  <span>{{ item.title }}</span>
                </v-chip>
              </template>

              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props" @click="() => {selectedProduct = item; }" class="my-6">
                  <v-list-item-content>
                    <v-list-item-title><strong>{{ item.title }}</strong></v-list-item-title>
                    <v-list-item-subtitle v-if="item.sku"><strong>sku : </strong> {{ item.sku }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.variations.length > 0"><strong>variations : </strong>  {{ formatVariation(item.variations) }}</v-list-item-subtitle>
                    <v-list-item-subtitle ><v-divider></v-divider></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
             <button
              type="button"
              class="add-btn"
              @click="addProduct"
            >
              <v-icon small class="mr-1">mdi-plus</v-icon>
              Add Product
            </button>
            </div>
            </div>
            <div style="width: 100%">
              <v-table>
              <thead>
                <tr>
                  <th class="text-left" style="width: 80%">
                    Product
                  </th>
                  <th class="text-left" style="width: 10%">
                    Price
                  </th>

                  <th class="text-left" style="width: 30%">
                    Quantity To Order
                  </th>
                  <th class="text-left" style="width: 10%">
                    Shipping Cost
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in products"
                  :key="index"
                  class="mb-3"
                >
                  <td style="width: 80%">
                    <div><strong>{{ item.product.title }}</strong></div>
                    <div v-if="item.product.sku"><small>SKU: {{ item.product.sku }}</small></div>
                    <div v-if="item.product.variations && item.product.variations.length > 0">
                      <small>Variations: {{ formatVariation(item.product.variations) }}</small>
                    </div>
                  </td>
                  <td style="width: 10%">{{ item.product.price}}</td>
                  <td style="width: 30%">  <input
                      type="number"
                      v-model="products[index].ordered_quantity"
                      :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
                      required
                    />
                </td>
                  <td style="width: 30%">  <input
                      type="number"
                      v-model="products[index].shipping_cost"
                      :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
                      required
                    />
                </td>
                </tr>
              </tbody>
            </v-table>
</div>
          </div>

    <div
        class="payment-detail-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <h3 style="margin-top: 0; margin-bottom: 15px">Tax & VAT</h3>

        <div class="form-row">
          <div class="form-group">
            <label for="tax_amount">TAX Amount</label>
            <input
              type="text"
              id="tax_amount"
              v-model="tax_amount"
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
           <div class="form-group">
            <label for="vat_amount">VAT Amount</label>
            <input
              type="text"
              id="vat_amount"
              v-model="vat_amount"
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>
      </div>


      <div
        class="buyer-detail-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <h3 style="margin-top: 0; margin-bottom: 15px">Customer Details</h3>

        <div class="form-row">
          <div class="form-group">
            <label for="name">Customer Name</label>
            <input
              type="text"
              id="name"
              v-model="name"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
          <div class="form-group">
            <label for="buyer_email">Customer Email</label>
            <input
              type="text"
              id="buyer_email"
              v-model="buyer_email"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <label for="first_line">Address First Line</label>
            <input
              type="text"
              id="first_line"
              v-model="first_line"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
          <div class="form-group">
            <label for="second_line">Address Second Line</label>
            <input
              type="text"
              id="second_line"
              v-model="second_line"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label for="city">City</label>
            <input
              type="text"
              id="city"
              v-model="city"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
          <div class="form-group">
            <label for="state">State</label>
            <input
              type="text"
              id="state"
              v-model="state"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <label for="zip">Zip</label>
            <input
              type="text"
              id="zip"
              v-model="zip"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
          <div class="form-group">
            <label for="country_iso">Country ISO</label>
            <input
              type="text"
              id="country_iso"
              v-model="country_iso"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>
      </div>


      <div
        class="payment-detail-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <h3 style="margin-top: 0; margin-bottom: 15px">Payment Details</h3>

        <div class="form-row">
          <div class="form-group">
            <label for="payment_method">Payment Method</label>
            <input
              type="text"
              id="payment_method"
              v-model="payment_method"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
          <div class="form-group">
            <label for="payment_email">Payment Email</label>
            <input
              type="text"
              id="payment_email"
              v-model="payment_email"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
          </div>
        <div class="form-row">

          <div class="form-group">
            <label for="message_from_payment">Message from Payment</label>
            <input
              type="text"
              id="message_from_payment"
              v-model="message_from_payment"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>
      </div>

      <div
        class="payment-detail-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <h3 style="margin-top: 0; margin-bottom: 15px">Message Details</h3>
        <div class="form-row">
          <div class="form-group">
            <label for="message_from_seller">Message from Seller</label>
            <input
              type="text"
              id="message_from_seller"
              v-model="message_from_seller"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>

          <div class="form-group">
            <label for="message_from_buyer">Message from Buyer</label>
            <input
              type="text"
              id="message_from_buyer"
              v-model="message_from_buyer"
              required
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>
      </div>

      <div
        class="payment-detail-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <h3 style="margin-top: 0; margin-bottom: 15px">Gift Details</h3>
        <div class="form-row">
          <div class="form-group">
            <label for="is_gift">Is Gift</label>
            <v-switch
              id="is_gift"
              v-model="is_gift"
              inset
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            >
            </v-switch>
          </div>
        </div>
        <div v-if="is_gift == true" class="form-row">
          <div class="form-group">
            <label for="gift_message">Gift Message</label>
            <input
              type="text"
              id="gift_message"
              v-model="gift_message"
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
          <div class="form-group">
            <label for="gift_sender">Gift Sender</label>
            <input
              type="text"
              id="gift_sender"
              v-model="gift_sender"
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined'"
            />
          </div>
        </div>
      </div>

      <div
        class="payment-detail-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <h3 style="margin-top: 0; margin-bottom: 15px">Status</h3>

        <div class="form-row">
          <div class="form-group">
            <label for="is_paid">Is Paid</label>
             <v-switch
              id="is_paid"
              v-model="is_paid"
              inset
              :disabled="receipt_id !== null && typeof receipt_id != 'undefined' && is_paid == true"
            >
            </v-switch>
          </div>
        </div>
      </div>

      <div
        class="payment-detail-container"
        style="border: 1px solid #ccc; padding: 15px; border-radius: 4px; margin-bottom: 15px"
      >
        <div class="form-row">
          <div class="form-group">
        <h3 style="margin-top: 0; margin-bottom: 15px">Shipment Information</h3>

            <div v-for="(shipment, index) in shipments" :key="index" class="shipment-info">
              <p>tracking code : {{ shipment.tracking_code }}</p>
              <p>carrier name : {{ shipment.carrier_name }}</p>
            </div>

            <div class="shipment-row">
              <div class="shipment-field">
                <label :for="`tracking_code_${index}`">Tracking Code</label>
                <input
                  type="text"
                  :id="`tracking_code_${index}`"
                  v-model="new_tracking_code"
                  placeholder="Enter tracking code"
                />
              </div>
              <div class="shipment-field">
                <label :for="`carrier_name_${index}`">Carrier Name</label>
                <input
                  type="text"
                  :id="`carrier_name_${index}`"
                  v-model="new_carrier_name"
                  placeholder="Enter carrier name"
                />
              </div>
            </div>
            <button
              type="button"
              class="add-btn"
              @click="addShipment"
              :disabled="new_tracking_code == '' || new_carrier_name == ''"
            >
              <v-icon small class="mr-1">mdi-plus</v-icon>
              Add Another Shipment
            </button>
          </div>
        </div>
      </div>

      <button type="submit">Submit</button>
    </form>

  </div>
</template>



<script>
import axios from 'axios'
import store from '@/store'

export default {
  data() {
    return {
      receipt_id: this.$route.params.receipt_id || null,
      shop: null,
      name: this.name || 'John Doe',
      buyer_email: this.buyer_email || 'john.doe@example.com',
      first_line: this.first_line || '123 Main St',
      second_line: this.second_line || 'Apt 4B',
      city: this.city || 'New York',
      state: this.state || 'NY',
      zip: this.zip || '10001',
      country_iso: this.country_iso || 'US',
      payment_method: this.payment_method || 'CC',
      payment_email: this.payment_email || 'payment@example.com',
      message_from_payment: this.message_from_payment || 'Payment received',
      message_from_seller: this.message_from_seller || 'Thank you for your purchase',
      message_from_buyer: this.message_from_buyer || 'Please deliver between 9 AM and 5 PM',
      is_shipped: this.is_shipped !== undefined ? this.is_shipped : false,
      is_paid: this.is_paid !== undefined ? this.is_paid : false,
      is_gift: this.is_gift !== undefined ? this.is_gift : false,
      gift_message: this.gift_message || 'Happy Birthday!',
      gift_sender: this.gift_sender || 'Jane Doe',
      gift_wrap_price: this.gift_wrap_price || 0,
      discount_amount: this.discount_amount || 0,
      tax_amount: this.tax_amount || 0,
      vat_amount: this.vat_amount || 0,
      productOptions: [],
      result: '',
      shipments: this.shipments || [],
      new_tracking_code: '',
      new_carrier_name: '',
      products: this.products || [],
      selectedProduct: '',
      transactions: this.transactions || [],
    }
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.receipt_id = this.$route.params.receipt_id
    if (this.receipt_id) {
      this.fetchOrderDetails(this.receipt_id)
    }
  },
  methods: {
    async submitForm() {

      var transactions = []
      var receipt_id = this.receipt_id ? this.receipt_id : Math.floor(Math.random() * 10000000000)
      var total_price = 0;
      var total_shipping_cost = 0;
      if (this.receipt_id) {
        transactions = this.transactions
        this.transactions.forEach(product => {
          total_price = total_price + ((product.price.amount / 100) * product.quantity)
          total_shipping_cost = total_shipping_cost + (product.shipping_cost.amount / 100)
        })
      } else {
        this.products.forEach(product => {
        var transaction = this.formatTransaction(product, receipt_id)
         total_price = total_price + (product.product.price * product.ordered_quantity)
          total_shipping_cost = total_shipping_cost + product.shipping_cost
          transactions.push(transaction)
        })
      }

      var is_shipped = this.shipments.length === 0 ? false : true
      var status = this.is_paid === true ? 'Paid' : 'none'
      status = is_shipped == true ? 'Completed' : status
      // Handle form submission
      const formData = {
        receipt_id: receipt_id,
        receipt_type: 0,
        seller_user_id: 181647289,
        seller_email: 'dpl@dpl.company',
        buyer_user_id: 39465590,
        buyer_email: this.buyer_email,
        name: this.name,
        first_line: this.first_line,
        second_line: this.second_line,
        city: this.city,
        state: this.state,
        zip: this.zip,
        status: status,
        formatted_address:
          this.name +
          '\n' +
          this.first_line +
          '\n' +
          this.city +
          ',' +
          this.state +
          ' ' +
          this.zip +
          '\n' +
          this.country_iso,
        country_iso: this.country_iso,
        payment_method: this.payment_method,
        payment_email: this.payment_email,
        message_from_payment: this.message_from_payment,
        message_from_seller: this.message_from_seller,
        message_from_buyer: this.message_from_buyer,
        is_shipped: is_shipped,
        is_paid: this.is_paid,
        create_timestamp: Math.floor(Date.now() / 1000),
        created_timestamp: Math.floor(Date.now() / 1000),
        update_timestamp: Math.floor(Date.now() / 1000),
        updated_timestamp: Math.floor(Date.now() / 1000),
        is_gift: this.is_gift,
        gift_message: this.is_gift == true ? this.gift_message : '',
        gift_sender: this.is_gift == true ? this.gift_sender : '',
        grandtotal: {
          amount: (total_price - this.discount_amount + this.tax_amount + this.vat_amount + this.gift_wrap_price) * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        subtotal: {
          amount: (total_price - this.discount_amount) * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        total_price: {
          amount: total_price * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        total_shipping_cost: {
          amount: total_shipping_cost * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        total_tax_cost: {
          amount: this.tax_amount * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        total_vat_cost: {
          amount: this.vat_amount * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        discount_amt: {
          amount: this.discount_amount * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        gift_wrap_price: {
          amount: this.gift_wrap_price * 100,
          divisor: 100,
          currency_code: 'USD',
        },
        shipments: this.shipments,
        transactions: transactions,
        refunds: [],
        uri: '/shops/18772841/receipts/' + receipt_id,
      }
      const jsonPrepare = {
        count: 1,
        results: [formData],
      }
      this.result = JSON.stringify(formData, null, 2)
      try {
        if (this.receipt_id) {
          const response = await axios.put(`/admin/mock/order/${this.receipt_id}`, {
            data: this.result,
          })
        } else {
          const response = await axios.post(`/admin/mock/order`, {
            data: this.result,
          })
        }

        this.$router.push({ name: 'order-mock-list' })
      } catch (error) {
        console.error('Error fetching products:', error)
      }
    },

    async searchProducts(searchTerm) {
      if (searchTerm.length < 3) {
        return
      }
      try {
        const response = await axios.get(`/admin/shop/${this.shop.id}/etsy-variations`, {
          params: { search: searchTerm },
        })
        this.productOptions = response.data.products
      } catch (error) {
        console.error('Error fetching products:', error)
      }
    },

    formatTransaction(selectedProduct, receipt_id) {
      const currentTime = Math.floor(Date.now() / 1000)

      return {
        transaction_id: Math.floor(Math.random() * 10000000000),
        title: selectedProduct.product.title,
        description: '',
        seller_user_id: 181647289,
        buyer_user_id: 39465590,
        create_timestamp: currentTime,
        created_timestamp: currentTime,
        paid_timestamp: currentTime,
        shipped_timestamp: currentTime,
        quantity: selectedProduct.ordered_quantity,
        listing_image_id: 6014142094,
        receipt_id: receipt_id,
        is_digital: false,
        file_data: '',
        listing_id: selectedProduct.product.listing_id,
        sku: selectedProduct.product.sku,
        product_id: 17412655865,
        transaction_type: 'listing',
        price: {
          'amount': selectedProduct.product.price * 100,
          'divisor': 100,
          'currency_code': 'USD',
        },
        shipping_cost: {
          'amount': selectedProduct.shipping_cost * 100,
          'divisor': 100,
          'currency_code': 'USD',
        },
        variations: selectedProduct.product.variations,
        product_data: selectedProduct.product.product_data,
        shipping_profile_id: 174481288654,
        min_processing_days: 1,
        max_processing_days: 2,
        shipping_method: null,
        shipping_upgrade: null,
        expected_ship_date: 1729530140,
        buyer_coupon: 0,
        shop_coupon: 0,
      }
    },

    async fetchOrderDetails(receipt_id) {
      try {
        const response = await axios.get(`/admin/mock/order/${receipt_id}`)
        const order = JSON.parse(response.data.data)
        // Populate form fields with order data
        this.name = order.name
        this.buyer_email = order.buyer_email
        this.first_line = order.first_line
        this.second_line = order.second_line
        this.city = order.city
        this.state = order.state
        this.zip = order.zip
        this.country_iso = order.country_iso
        this.payment_method = order.payment_method
        this.payment_email = order.payment_email
        this.message_from_payment = order.message_from_payment
        this.message_from_seller = order.message_from_seller
        this.message_from_buyer = order.message_from_buyer
        this.is_shipped = order.is_shipped
        this.is_paid = order.is_paid
        this.is_gift = order.is_gift
        this.gift_message = order.gift_message
        this.gift_sender = order.gift_sender
        this.status = order.status
        this.transactions = order.transactions
        this.gift_wrap_price = order.gift_wrap_price.amount / 100
        this.discount_amount = order.discount_amt.amount / 100
        this.tax_amount = order.total_tax_cost.amount / 100
        this.vat_amount = order.total_vat_cost.amount / 100
        // Load shipments data if available
        if (order.shipments && order.shipments.length > 0) {
          this.shipments = order.shipments
        }

        // Populate product selections
        order.transactions.forEach((transaction, index) => {
          this.products.push({
            product: {
              title: transaction.title,
              price: transaction.price.amount / 100,
              listing_id: transaction.listing_id,
              sku: transaction.sku,
              product_data: transaction.product_data,
              variations: transaction.variations,
            },
            ordered_quantity: transaction.quantity,
            shipping_cost: transaction.shipping_cost.amount / 100,
          })
        })
      } catch (error) {
        console.error('Error fetching order details:', error)
      }
    },

    // Add new methods for shipment handling
    addShipment() {
      this.shipments.push({
        receipt_shipping_id: Math.floor(Math.random() * 10000000000),
        shipment_notification_timestamp: Math.floor(Date.now() / 1000),
        tracking_code: this.new_tracking_code,
        carrier_name: this.new_carrier_name,
      })
      this.new_tracking_code = ''
      this.new_carrier_name = ''
    },

    removeShipment(index) {
      this.shipments.splice(index, 1)
    },

    addProduct() {
      this.products.push({
        product: this.selectedProduct,
        ordered_quantity: 1,
        shipping_cost: 0,
      })
    },

    deleteProduct(index) {
      this.products.splice(index, 1)
    },

    formatProductSelection(product) {
      return {
        title: product.title,
        subtitle: product.key,
      }
    },
    viewOrders() {
      this.$router.push({ name: 'order-mock-list' })
    },
    formatVariation(variations) {
      return variations.map(variation => `${variation.formatted_name}: ${variation.formatted_value}`).join(', ')
    },
  },
}
</script>

<style scoped>
.order-mock-form {
  max-width: '100%';
  margin: 0 auto;
  padding: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  margin-bottom: 15px;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.shipment-info {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.shipment-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.shipment-field {
  flex: 1;
}

.remove-btn {
  background-color: #dc3545;
  padding: 5px 10px;
  margin-top: 20px;
}

.remove-btn:hover {
  background-color: #c82333;
}

.add-btn {
  background-color: #28a745;
  margin-top: 5px;
  display: block;
}

.add-btn:hover {
  background-color: #218838;
}
</style>
